import React from "react"
import Img from 'gatsby-image';

const HeroImage = ({markdown}) => {
    return (
        <>
        <section className="-mt-20">
                <div className="px-2 bg-primary bg-opacity-75 text-center absolute text-white fill-current left-50 z-10 transform -translate-x-1/2 -translate-y-1/2" style={{top: "150px"}}>
                    <h1 className="px-2 text-xl md:text-2xl lg:text-4xl tracking-widest text-white">{ markdown.frontmatter.title }</h1>
                </div>
                {markdown.frontmatter.featureimage.extension === 'svg'?
                <img className="w-screen h-72" src={markdown.frontmatter.featureimage.publicURL} alt={markdown.frontmatter.featureimagealt} />
                :
                <Img className="w-screen h-72" fluid={markdown.frontmatter.featureimage.childImageSharp.fluid} alt={markdown.frontmatter.featureimagealt} />}
            </section>
        </>
    )
}

export default HeroImage